import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IuserDetail } from '../interface/interface.service';

@Injectable({
  providedIn: 'root'
})
export class UserDetailService {
  messageSource = new BehaviorSubject<IuserDetail | null>(null);
  currentMessage = this.messageSource.asObservable();

  constructor() { }

  changeMessage(data: IuserDetail | null){
    this.messageSource.next(data);
  }
}
