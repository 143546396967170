import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

export enum snackBarClass{
  Info = 'snackBarInfo',
  Success = 'snackBarSuccess',
  Warning = 'snackBarWarning',
  Error = 'snackBarError'
}

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private matSnackBar: MatSnackBar){}

  showSnackBar(message: string, className: snackBarClass, action: string = 'Okay', duration: number = 4000, verticalPosition: 'top' | 'bottom' = 'bottom', horizontalPosition: 'left' | 'center' | 'right' = 'center'){
    this.matSnackBar.open(message, action, {
      duration: duration,
      panelClass: className,
      verticalPosition: verticalPosition,
      horizontalPosition: horizontalPosition
    });
  }
}
