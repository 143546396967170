export const protectedResource = {
    country: {
        resourceURL: 'location/country'
    },
    state: {
        resourceURL: 'location/state'
    },
    city: {
        resourceURL: 'location/city'
    },
    userRregistration: {
        resourceURL: 'user/registration'
    },
    userValidateRegistration: {
        resourceURL: 'user/validateRegistration'
    },
    userLogin: {
        resourceURL: 'user/login'
    },
    userForgotPassworkUserCheck: {
        resourceURL: 'user/forgotPassworkUserCheck'
    },
    userForgotPassword: {
        resourceURL: 'user/forgotPassword'
    },
    userRefreshToken: {
        resourceURL: 'user/refreshToken'
    },
    userLogout: {
        resourceURL: 'user/logout'
    },
    user: {
        resourceURL: 'user'
    },
    userStatus: {
        resourceURL: 'user/userStatus'
    },
    userRole: {
        resourceURL: 'user/userRole'
    },
    loginTracker: {
        resourceURL: 'user/loginTracker'
    },
    galleryCategory: {
        resourceURL: 'galleryCategory'
    },
    gallery: {
        resourceURL: 'gallery'
    },
    eventDetail: {
        resourceURL: 'event/detail'
    },
    event: {
        resourceURL: 'event'
    },
    ebook: {
        resourceURL: 'ebook'
    },
    ebookTracker: {
        resourceURL: 'ebook/tracker'
    },
    notification: {
        resourceURL: 'notification'
    },
    notificationPlatform: {
        resourceURL: 'notification/platform'
    },
    pushNotification: {
        resourceURL: 'notification/pushNotification'
    }
};